<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Riwayat Rekening</b> yang ada di {{ clinic_name }}
      </div>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <div class="row">
              <div class="col-md-6">
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('days')"
                  v-bind:class="{
                    'btn-info': btnActive.days,
                    'btn-outline-info': btnActive.days == false,
                  }"
                >
                  Hari Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('month')"
                  v-bind:class="{
                    'btn-info': btnActive.month,
                    'btn-outline-info': btnActive.month == false,
                  }"
                >
                  Bulan Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('lastMonth')"
                  v-bind:class="{
                    'btn-info': btnActive.lastMonth,
                    'btn-outline-info': btnActive.lastMonth == false,
                  }"
                >
                  Bulan Kemarin
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('year')"
                  v-bind:class="{
                    'btn-info': btnActive.year,
                    'btn-outline-info': btnActive.year == false,
                  }"
                >
                  Tahun Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  v-bind:class="{
                    'btn-info': btnActive.period,
                    'btn-outline-info': btnActive.period == false,
                  }"
                  @click="btnPeriodeOnClick"
                >
                  Periode
                </button>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <h5 v-if="detail != null">
                  Riwayat Rek. <b>{{ detail.name }}</b>
                </h5>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-md-6" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <b-form-input
                      v-model="filter.startDate"
                      type="text"
                      placeholder="Tanggal Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.startDate"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.endDate"
                      type="text"
                      placeholder="Tanggal Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.endDate"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <template #append>
                      <b-button squared @click="generalFilter" variant="success"
                        >Cari</b-button
                      >
                      <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      >
                    </template>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <b-form-input
                          v-model="filter.startDate"
                          type="text"
                          placeholder="Tanggal Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.startDate"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.endDate"
                          type="text"
                          placeholder="Tanggal Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.endDate"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <template #append>
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                          <b-button
                            squared
                            @click="generalFilter"
                            variant="success"
                            >Cari</b-button
                          >
                        </template>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>

            <b-tabs content-class="mt-3">
              <b-tab title="Pengeluaran" active>
                <div class="row">
                  <div class="col-md-12 d-flex justify-content-end">
                    <span
                      >Total Pengeluaran:
                      <b>{{
                        parseInt(totalExpenseAmount).toLocaleString("id-ID")
                      }}</b></span
                    >
                  </div>
                </div>
                <b-table
                  striped
                  hover
                  class="mt-3"
                  :items="items"
                  :fields="fieldExpense"
                  :style="'white-space: nowrap'"
                  responsive="sm"
                >
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'actions' ? '15%' : '' }"
                    />
                  </template>
                </b-table>
              </b-tab>

              <b-tab title="Pemasukan">
                <div class="row">
                  <div class="col-md-12 d-flex justify-content-end">
                    <span
                      >Total Pemasukkan:
                      <b>{{
                        parseInt(totalIncomeAmount).toLocaleString("id-ID")
                      }}</b></span
                    >
                  </div>
                </div>
                <b-table
                  striped
                  hover
                  class="mt-3"
                  :items="itemIncome"
                  :fields="fieldIncome"
                  :style="'white-space: nowrap'"
                  responsive="sm"
                >
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'actions' ? '15%' : '' }"
                    />
                  </template>
                  <template #cell(final_amount)="data">
                    <span>{{
                      parseInt(data.item.final_amount).toLocaleString("id-ID")
                    }}</span>
                  </template>
                </b-table>
              </b-tab>
            </b-tabs>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        startDate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "days",
      // Table Head
      fieldExpense: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "name",
          label: "Keterangan",
          sortable: true,
        },
        {
          key: "display_amount",
          label: "Pengeluaran (Rp)",
          sortable: true,
        },
        // {
        //   key: "display_deposit",
        //   label: "Deposit (Rp)",
        //   sortable: true,
        // },
        // {
        //   key: "display_deposit_balance",
        //   label: "Sisa Deposit (Rp)",
        //   sortable: true,
        // },
        {
          key: "source",
          label: "Source",
          sortable: true,
        },
      ],
      fieldIncome: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        // {
        //   key: "name",
        //   label: "Keterangan",
        //   sortable: true,
        // },
        {
          key: "final_amount",
          label: "Pemasukkan (Rp)",
          sortable: true,
        },
        // {
        //   key: "display_deposit",
        //   label: "Deposit (Rp)",
        //   sortable: true,
        // },
        // {
        //   key: "display_deposit_balance",
        //   label: "Sisa Deposit (Rp)",
        //   sortable: true,
        // },
        {
          key: "source",
          label: "Source",
          sortable: true,
        },
      ],
      // Table Items
      items: [],
      itemIncome: [],
      // data
      detail: [],
      totalExpenseAmount: 0,
      totalIncomeAmount: 0,
      // user access
      btn: false,
      clinic_name:
        window.configuration == null
          ? "Medhop"
          : window.configuration.clinic_name,
    };
  },

  methods: {
    filterByName() {
      this.dataExpense();
      this.dataIncome();
    },

    resetFilter() {
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.generalFilter();
    },

    generalFilter() {
      this.dataExpense();
      this.getDetail();
      this.dataIncome();
    },
    async getDetail() {
      let response = await module.get("bank-accounts/" + this.$route.params.id);
      this.detail = response;
      console.log(this.detail, "================ det ======================");
    },

    async dataExpense() {
      let filterParams = `?startDate=${this.filter.startDate}&endDate=${this.filter.endDate}`;
      let response = await module.paginate(
        "expenses/history/" + this.$route.params.id,
        `${filterParams}`
      );

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.items.length; a++) {
        response.data.items[a].number = b + a;
      }

      this.items = response.data.items;
      this.totalExpenseAmount = response.data.total_amount;
    },

    async dataIncome() {
      let filterParams = `?startDate=${this.filter.startDate}&endDate=${this.filter.endDate}`;
      let response = await module.paginate(
        "payment-slips/history/" + this.$route.params.id,
        `${filterParams}`
      );

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.items.length; a++) {
        response.data.items[a].number = b + a;
      }

      this.itemIncome = response.data.items;
      this.totalIncomeAmount = response.data.total_amount;
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.startDate = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.endDate = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.dataExpense();
        this.dataIncome();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "days";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.startDate = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.endDate = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.dataExpense();
        this.dataIncome();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "month";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.startDate = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.endDate = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.dataExpense();
        this.dataIncome();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "lastMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.startDate = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.endDate = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.dataExpense();
        this.dataIncome();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        this.lastPeriodType = "year";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.startDate = "";
        this.filter.endDate = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },

    // async dataIncome() {
    //   let filterParams = `&name=${this.filter.name}`
    //   let response = await module.paginate('bank-accounts', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)

    //   this.items = response.data
    // },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Rekening", route: "" },
      { title: "Daftar Rekening", route: "/masters/bank-accounts/list" },
      { title: "Riwayat Rekening" },
    ]);
    // Get Data
    this.dataExpense();
    this.getDetail();
    this.dataIncome();
  },
};
</script>
